html {
  height: 100%;
  font-size: 15px;
}

body {
  color: #4d4d4d;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.3;
}

p {
  margin-bottom: .5rem;
  font-size: inherit;
  line-height: 1.3;
}

ul {
  font-size: inherit;
}

a {
  transition: color .1s ease-in-out;
  color: #4cbac0;
}

a:hover,
a:focus,
a:active {
  color: #6fc7cc;
}

h1,
h2,
h3 {
  color: #666;
  font-weight: normal;
}

h1 {
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

@media screen and (min-width: 40.063em) {
  h1 {
    font-size: 2.3rem;
  }
}

h2 {
  font-size: 1.6rem;
}

@media screen and (min-width: 40.063em) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.2rem;
}

label {
  display: block;
  margin-bottom: .6180469716rem;
  color: #999;
  font-size: 13px;
  line-height: 1.5;
  cursor: pointer;
}

input:not([type='checkbox']):not([type='radio']),
select,
textarea {
  transition: all .15s linear, background .15s linear;
  border: 1px solid #cccbcb;
  outline: none;
  background-color: #fff;
  color: #47473e;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  box-shadow: none;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input [type='checkbox'] {
  -moz-appearance: checkbox;
  -webkit-appearance: checkbox;
  box-sizing: border-box;
}

input [type='radio'] {
  -moz-appearance: radio;
  -webkit-appearance: radio;
  box-sizing: border-box;
}

select {
  border: 1px solid #f2f2f0;
  background-color: #f2f2f0;
}

select:hover {
  border-color: #f2f2f0;
  background-color: #f7f7f7;
}

::-webkit-input-placeholder {
  opacity: .4;
}

::-moz-placeholder {
  /* Firefox 18- */
  opacity: .4;
}

:-ms-input-placeholder {
  opacity: .4;
}

:focus::-webkit-input-placeholder {
  -webkit-transition: opacity .55s ease-in-out;
  transition: opacity .55s ease-in-out;
  opacity: 0;
}

:focus::-moz-placeholder {
  -moz-transition: opacity .55s ease-in-out;
  transition: opacity .55s ease-in-out;
  opacity: 0;
}

:focus:-ms-input-placeholder {
  -ms-transition: opacity .55s ease-in-out;
  transition: opacity .55s ease-in-out;
  opacity: 0;
}

input:focus {
  border-color: #f2f2f0;
}

input.has-error,
textarea.has-error,
select.has-error {
  margin-bottom: 0;
  transition: margin-bottom 0;
  border-color: #de5b6b !important;
}

input.has-success,
textarea.has-success,
select.has-success {
  border-color: #4cbac0 !important;
}

input.form__element,
select.form__element,
.form__element {
  display: block;
  width: 100%;
  height: 2.5333333333rem;
  margin: 0 0 1.0666666667rem;
  padding: .5333333333rem;
  border: 1px solid #cccbcb;
  border-radius: 2px;
  background-color: #fff;
}

input.form__element--big,
select.form__element--big {
  height: 3.3886408256rem;
  padding: .8rem;
  font-size: 1.2944rem;
}

input.form__element:focus,
select.form__element:focus {
  border-color: #80807f;
}

input.form__element--ghost,
.form__element--ghost {
  border: 0;
  background: none;
}

input.form__element--ghost:focus,
.form__element--ghost:focus {
  background: none;
}

.layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  background: #f2f2f0;
}

.layout__content {
  position: absolute;
  top: 3.3886408256rem;
  right: 0;
  left: 0;
  padding: 0;
  transition: all .3s ease-in-out;
}

.layout___content-app-background {
  height: 100%;
  width: 100%;
  background-image: url("../images/reg-bg-min.png");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

.frontpage-tooltip-link {
  display: inline;
  margin-top: .43rem;
  float: right;
}

[role='tooltip'] {
  display: none;
  position: absolute;
  top: 1.2944rem;
  right: 0;
  width: 15rem;
  margin-top: .8rem;
  padding: 1.2944rem 1.618rem;
  background-color: #47473e;
  color: #fff;
  font-size: .8rem;
}

.frontpage-tooltip-link:hover + [role='tooltip'],
.frontpage-tooltip-link:focus + [role='tooltip'] {
  display: block;
  opacity: .9;
}

@keyframes pulse {
  from,
  to {
    transform: scale(1);
    opacity: 1;
  }
  40% {
    transform: scale(0.98);
    opacity: .2;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.spinner {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-image: url("/images/spinner.gif");
  background-repeat: none;
  background-size: cover;
  vertical-align: middle;
  user-select: none;
}

.spinner::before, .spinner::after {
  display: none;
}

.cssanimations .spinner {
  animation: spin .7s linear;
  border: 5px solid #cccbcb;
  border-radius: 100%;
  border-top-color: transparent;
  background: none;
  animation-iteration-count: infinite;
}

.spinner.spinner--small {
  width: 1rem;
  height: 1rem;
}

.cssanimations .spinner.spinner--small {
  border-width: 3px;
}

.loading {
  padding: 1rem;
}

.loading--inline {
  display: inline-block;
  padding: 0;
  background: red;
}

.loading__spinner,
.loading__text {
  vertical-align: middle;
}

.loading__spinner {
  display: none;
}

html.cssanimations .loading__spinner {
  display: inline-block;
}

.loading__text {
  display: inline-block;
  margin-left: .8rem;
  color: #a09f9f;
  font-size: 1.2944rem;
  user-select: none;
}

.icon {
  display: inline-block;
  width: 3.3886408256rem;
  max-width: 100%;
  height: 3.3886408256rem;
  max-height: 100%;
  vertical-align: middle;
}

.icon--micro {
  width: .8rem;
  height: .8rem;
}

.icon--tiny {
  width: 1rem;
  height: 1rem;
}

.icon--small {
  width: 1.2944rem;
  height: 1.2944rem;
}

.icon--medium {
  width: 2.0943392rem;
  height: 2.0943392rem;
}

.icon--large {
  width: 3.3886408256rem;
  height: 3.3886408256rem;
}

.icon--jumbo {
  width: 5.4828208558rem;
  height: 5.4828208558rem;
}

.card {
  margin: 0 auto;
  padding: 1.2944rem 1.618rem;
  border-radius: 0;
  background-color: #fff;
}

@media screen and (min-width: 40.063em) {
  .card {
    border-radius: 1px;
    box-shadow: 0 0 10px rgba(204, 203, 203, 0.5);
  }
}

.card.is-focused {
  box-shadow: 0 0 10px rgba(71, 71, 62, 0.2);
}

.card--icon {
  display: flex;
}

.card--icon .card__icon {
  display: none;
}

@media screen and (min-width: 40.063em) {
  .card--icon .card__icon {
    display: flex;
    width: 3.3886408256rem;
    height: 3.3886408256rem;
    margin: .8rem 0 0;
  }
}

.card--icon .card__content {
  padding-left: 1rem;
  flex: 1;
}

@media screen and (min-width: 40.063em) {
  .card--icon .card__content {
    padding-left: 1.2944rem;
  }
}

.footer {
  padding: 1.2944rem 1rem;
}

.footer a {
  color: #666;
}

.footer a:hover,
.footer a:focus,
.footer a:active {
  color: #4d4d4d;
}

.footer__text {
  font-size: .8rem;
  text-align: center;
}

.logo {
  display: block;
  max-width: 100%;
  height: auto;
  clear: both;
  vertical-align: middle;
}

.logo--front-page,
.logo--front-page img {
  max-width: 11rem;
  margin: 0 auto;
}

.logo--front-page img {
  display: block;
  max-height: 11rem;
}

.logo--front-page__disclaimer {
  max-width: 17.9rem;
  margin: 1.29rem auto 0;
  color: #666;
  font-size: .8rem;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
}

.slat {
  display: flex;
  padding: 1rem .8rem;
  border-bottom: 1px solid #f2f2f0;
  align-items: center;
}

.slat:before, .slat:after {
  display: table;
  content: '';
}

.slat:after {
  clear: both;
}

.slat:last-of-type {
  border-bottom: 0;
}

.no-flexbox .slat {
  display: block;
}

.slat__content {
  display: inline-block;
  overflow: hidden;
  flex: 1;
}

.no-flexbox .slat__content {
  display: block;
}

.no-flexbox .slat__title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.slat__text {
  color: #a09f9f;
  font-size: .8rem;
  font-style: italic;
}

.no-flexbox .slat__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.slat__icon {
  display: inline-block;
  margin-right: 1rem;
  align-self: flex-start;
}

.no-flexbox .slat__icon {
  float: left;
}

.slat--base {
  padding: 0;
  border: 0;
}

.slat--invisible {
  border: 0;
}

.slat--v-collapse {
  padding-top: 0;
  padding-bottom: 0;
}

.slat--h-collapse {
  padding-right: 0;
  padding-left: 0;
}

.slat--h-collapse .slat__icon {
  margin-right: .8rem;
}

.slat--h-collapse .slat__tools {
  margin-left: .8rem;
}

.slat--verticalcenter .slat__content {
  vertical-align: middle;
}

.button {
  display: inline-block;
  position: relative;
  margin-right: .8rem;
  margin-bottom: 1rem;
  padding: .8rem 1.6rem .8666666667rem;
  transition: all .1s ease-in-out;
  border: 0;
  border-radius: 3px;
  background-color: #f2f2f0;
  color: #4d4d4d;
  font-size: 1rem;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.button:last-of-type {
  margin-right: 0;
}

.button > .icon {
  margin-right: 0.6180469716rem;
  vertical-align: middle;
}

.button--primary,
.button--front-page {
  border: 0;
  background-color: #4cbac0;
  color: #fff;
  text-transform: uppercase;
}

.button.button--clear,
a.button.button--clear {
  border: 1px solid #cccbcb;
  background: transparent;
  box-shadow: 0 2px 4px rgba(71, 71, 62, 0.2);
}

.button.button--clear:hover,
a.button.button--clear:hover {
  border: 1px solid #cccbcb;
  background: transparent;
}

.button.button--disabled, .button.has-spinner, .button.button--primary.button--disabled,
.button.button--primary.is-disabled,
.button.button--front-page.button--disabled,
.button.button--front-page.is-disabled, .button.button--primary.button--disabled.has-spinner,
.button.button--primary.is-disabled.has-spinner,
.button.button--front-page.button--disabled.has-spinner,
.button.button--front-page.is-disabled.has-spinner,
.button.is-disabled {
  cursor: default;
  opacity: .7;
}

.button--large {
  padding: 1rem 2rem 1.0666666667rem;
  font-size: 1rem;
}

.button:hover {
  background-color: #e1e1dd;
  color: #4d4d4d;
}

.button--primary:hover,
.button--front-page:hover {
  background-color: #6fc7cc;
  color: #fff;
}

.button.has-spinner .spinner {
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  margin-left: -1rem;
  border-color: #cccbcb;
  border-top-color: transparent;
}

.cssanimations .button.has-spinner .spinner {
  border-width: 3px;
}

.button--primary.button.has-spinner, .button--primary.button.button--disabled,
.button--primary.button.is-disabled, .button--primary.button.button--disabled.has-spinner,
.button--primary.button.is-disabled.has-spinner,
.button.button--primary.is-disabled,
.button--front-page.button.has-spinner,
.button--front-page.button.button--disabled,
.button--front-page.button.is-disabled,
.button--front-page.button.button--disabled.has-spinner,
.button--front-page.button.is-disabled.has-spinner,
.button.button--front-page.is-disabled {
  border-color: #4cbac0;
  background-color: #4cbac0;
}

.button.button--primary.button--disabled.has-spinner,
.button.button--primary.is-disabled.has-spinner,
.button.button--front-page.button--disabled.has-spinner,
.button.button--front-page.is-disabled.has-spinner {
  background-color: #4cbac0;
}

.button--primary.has-spinner.button .spinner, .button--primary.has-spinner.button.button--disabled .spinner,
.button--primary.has-spinner.button.is-disabled .spinner,
.button.button--primary.is-disabled.has-spinner .spinner, .button--front-page.has-spinner.button .spinner,
.button--front-page.has-spinner.button.button--disabled .spinner,
.button--front-page.has-spinner.button.is-disabled .spinner,
.button.button--front-page.is-disabled.has-spinner .spinner {
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  margin-left: -1rem;
  border-color: #fff;
  border-top-color: transparent;
}

.cssanimations .button--primary.has-spinner.button .spinner, .cssanimations .button--primary.has-spinner.button.button--disabled .spinner, .cssanimations
.button--primary.has-spinner.button.is-disabled .spinner, .cssanimations
.button.button--primary.is-disabled.has-spinner .spinner, .cssanimations .button--front-page.has-spinner.button .spinner, .cssanimations
.button--front-page.has-spinner.button.button--disabled .spinner, .cssanimations
.button--front-page.has-spinner.button.is-disabled .spinner, .cssanimations
.button.button--front-page.is-disabled.has-spinner .spinner {
  border-width: 3px;
}

.button.button--primary.has-spinner.button--disabled,
.button.button--primary.has-spinner.is-disabled,
.button.button--front-page.has-spinner.button--disabled,
.button.button--front-page.has-spinner.is-disabled {
  background-color: #4cbac0;
}

.button.button--block {
  display: block;
}

button.button.button--block {
  width: 100%;
}

.textbutton,
a.textbutton {
  display: inline-block;
  position: relative;
  margin-right: .8rem;
  margin-bottom: .8rem;
  padding-right: .8rem;
  padding-left: .8rem;
  transition: all .1s ease-in-out;
  border: 0;
  background: none;
  color: #4cbac0;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.textbutton:hover,
a.textbutton:hover {
  color: #6fc7cc;
}

.textbutton:last-of-type,
a.textbutton:last-of-type {
  margin-right: 0;
}

.textbutton.has-spinner,
a.textbutton.has-spinner {
  padding-left: 2.6rem;
  transition: padding .1s ease-out;
}

.textbutton .spinner,
a.textbutton .spinner {
  width: 1rem;
  height: 1rem;
  margin-right: .2rem;
  margin-left: -1rem;
}

.cssanimations .textbutton .spinner, .cssanimations
a.textbutton .spinner {
  border-width: 3px;
}

.textbutton.textbutton--primary {
  color: #4cbac0;
}

.textbutton.textbutton--primary:hover {
  color: #6fc7cc;
}

.textbutton.textbutton--front-page {
  color: #4cbac0;
}

.textbutton.textbutton--front-page:hover {
  color: #6fc7cc;
}

.actions {
  margin: 2rem 0 1rem;
}

.actions:before, .actions:after {
  display: table;
  content: '';
}

.actions:after {
  clear: both;
}

.actions:empty {
  margin: 0;
}

@media screen and (max-width: 40em) {
  .actions .button,
  .actions .splitbutton,
  .actions .textbutton {
    display: block;
    width: 100%;
    margin-right: 0;
  }
}

@media screen and (min-width: 40.063em) {
  .actions .button,
  .actions .splitbutton,
  .actions .textbutton {
    margin-bottom: 0;
  }
}

.actions--stacked .button,
.actions--stacked .splitbutton,
.actions--stacked .textbutton {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-bottom: .8rem;
}

.notification {
  position: relative;
}

.notification--top {
  position: fixed;
  top: 0;
  left: 50%;
  width: 37.6rem;
  max-width: 80%;
  margin: 0;
  padding: 1.2944rem 1.618rem;
  transform: translateX(-50%);
  transition: all .2s ease-in-out;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 0 4px #a6a2a1;
  opacity: 0;
  z-index: -1;
}

.notification--top.is-showing {
  top: 1.6rem;
  opacity: 1;
  z-index: 100;
}

.u-text__center {
  text-align: center;
}

.u-text__small {
  font-size: .8rem;
}

.u-text__linethrough {
  text-align: center;
  overflow: hidden;
}

.u-text__linethrough::before, .u-text__linethrough::after {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 1px;
  background-color: currentColor;
  content: '';
  vertical-align: middle;
}

.u-text__linethrough::before {
  right: 1rem;
  margin-left: -50%;
}

.u-text__linethrough::after {
  left: 1rem;
  margin-right: -50%;
}

.u-box__centered {
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 40.063em) {
  .u-box__centered {
    max-width: 37.5766556713rem;
  }
}

.u-box__hide-small {
  visibility: hidden;
}

@media screen and (min-width: 40.063em) {
  .u-box__hide-small {
    visibility: visible;
  }
}

.u-box__inline {
  display: inline;
}

.u-box__mt0 {
  margin-top: 0;
}

.u-box__mt1 {
  margin-top: 1rem;
}

.u-box__mt2 {
  margin-top: 1.2944rem;
}

.u-box__mt3 {
  margin-top: 1.618rem;
}

.u-box__mt4 {
  margin-top: 2.018rem;
}

.u-box__mt5 {
  margin-top: 2.617924rem;
}

.u-box__mr1 {
  margin-right: 1rem;
}

.u-box__mr2 {
  margin-right: 1.2944rem;
}

.u-box__mr3 {
  margin-right: 1.618rem;
}

.u-box__mb1 {
  margin-bottom: 1rem !important;
}

.u-box__mb2 {
  margin-bottom: 1.2944rem;
}

.u-box__mb3 {
  margin-bottom: 1.618rem !important;
}

.u-box__mb4 {
  margin-bottom: 2.018rem;
}

.u-box__mb5 {
  margin-bottom: 2.617924rem;
}

.u-box__ml1 {
  margin-left: 1rem;
}

.u-box__ml2 {
  margin-left: 1.2944rem;
}

.u-box__ml3 {
  margin-left: 1.618rem;
}

.u-box__p0 {
  padding: 0;
}

.u-box__pt0 {
  padding-top: 0;
}

.u-box__pl0 {
  padding-left: 0 !important;
}

.u-box__p1 {
  padding: 1rem;
}

.u-text__light {
  color: #a09f9f;
}

.u-text__quiet, .u-text__help {
  color: #a09f9f;
  font-size: .8rem;
}

.u-text__help {
  font-style: italic;
}

.u-color__dl-grey {
  color: #cccbcb !important;
}

.u-box__75width-mediumup {
  width: 100%;
}

@media screen and (min-width: 40.063em) {
  .u-box__75width-mediumup {
    width: 75%;
    float: left;
  }
}

.u-box__50width-mediumup {
  width: 100%;
}

.u-box__50width-mediumup:before, .u-box__50width-mediumup:after {
  display: table;
  content: '';
}

.u-box__50width-mediumup:after {
  clear: both;
}

@media screen and (min-width: 40.063em) {
  .u-box__50width-mediumup {
    width: 48%;
  }
  .u-box__50width-mediumup:first-of-type {
    margin-right: .65rem;
    float: left;
  }
  .u-box__50width-mediumup:last-of-type {
    margin-left: .65rem;
    float: right;
  }
}

.u-box__25width-mediumup {
  width: 100%;
}

@media screen and (min-width: 40.063em) {
  .u-box__25width-mediumup {
    width: 25%;
    float: right;
  }
}

.u-box__fl {
  float: left;
}

.u-box__fr {
  float: right;
}

.u-pos__vcenter {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.u-pos__relative {
  position: relative;
}

@media screen and (min-width: 40.063em) {
  .u-box__checkspacing-mediumup {
    margin-top: 1.2944rem;
    margin-left: 1rem;
  }
}

.is-gone {
  display: none !important;
}

.twitter-typeahead {
  width: 100%;
  margin-bottom: 1rem;
}

.twitter-typeahead--scroll .tt-dropdown-menu {
  max-height: 7rem;
  overflow-y: auto;
}

input.tt-input {
  margin-bottom: 0;
}

.tt-dropdown-menu {
  display: block;
  min-width: 100%;
  padding: 0;
  border: 1px solid #cccbcb;
  background: #fff;
  box-shadow: 1px 1px 2px rgba(130, 181, 226, 0.1);
}

.tt-dropdown-menu * {
  margin: 0;
}

.tt-suggestion {
  padding: .29rem .8rem;
  border-bottom: 1px solid #f2f2f0;
}

.tt-suggestion:last-child {
  border-bottom: 0;
}

.tt-suggestion.tt-cursor {
  background: #82b5e2;
  color: #fff;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  background: rgba(166, 162, 161, 0.5);
  opacity: 0;
  z-index: 100;
}

.modal__inner {
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  display: inline-block;
  width: 90%;
  max-width: 90%;
  max-height: 100%;
  padding: 1.2944rem 1.618rem;
  border-radius: 3px;
  background: #fff;
  box-shadow: 1px 1px 4px rgba(71, 71, 62, 0.1);
  overflow-y: auto;
  overflow-x: visible;
}

@media screen and (min-width: 40.063em) {
  .modal__inner {
    width: auto;
    min-width: 40.0301727992rem;
  }
}

@media screen and (min-width: 64em) {
  .modal__inner {
    max-height: 90%;
  }
}

.modal__inner::-webkit-scrollbar {
  width: 0.6rem;
}

.modal__inner::-webkit-scrollbar-thumb {
  background: #cccbcb;
}

.modal__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.8rem;
  height: 0.8rem;
  padding: 0.8rem;
  cursor: pointer;
  opacity: .5;
  box-sizing: content-box;
}

.modal__close:hover {
  opacity: 1;
}

.modal__close svg {
  display: block;
}

.modal.is-visible {
  opacity: 1;
}

.promo-input {
  margin-top: 1rem;
  clear: both;
}

@media screen and (min-width: 40.063em) {
  .promo-input {
    margin-top: 4.4rem;
  }
}
